.edit_cell_1 {
    border: solid 1px #DCDEE0;
    border-radius: 4px;
    height: 120px;
    margin-right: 15px;
    margin-top:15px;
}
.edit_right {
    margin-right: 0px;
}
.title_box {
    margin-top: 20px;
}
.edit_title {
    margin-left: 15px;
    font-size: 20px;
    color: #323233;
    margin-top: 30px;
}
.des_box {
    margin-top: 20px;
}
.edit_des {
    font-size: 14px;
    color: #A5A5A5;
    margin-left: 15px;
}

.edit_cell_2 {
    border: solid 1px #155BD4;
    background-color: #EDF4FF;
    border-radius: 4px;
    height: 120px;
    margin-right: 15px;
    margin-top:15px;
    
}

.warning_box {
    margin-top: 10px;
}
.dialog_cus {
    width: 718px;
}
.red_txt {
    color: #FF6462;
    font-size: 14px;
}
.pm_box {
    display: flex;
    flex-direction: row;
}
.pm_left {
    width: 50%;
}
.pm_right {
    width: 50%;
}
.pm_left_qrbox {
    width: 100%;
    padding-top:20px;
    text-align: center;
    /* background-color: #F7F8FA; */
}
.qr_img {
    width: 80%;
}
.qr_no_img{
    color: #155BD4;
    width: 80%;
    display: inline-block;
    text-align: center;
    line-height: 32px;
}

.pm_right {
    display: flex;
    flex-direction: column;
}
.copy_box {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
}
.pm_demo_img {
    width: 240px;
    margin-top: 10px;
}
.pm_tg_right_row {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    margin-bottom: 15px;
}
.pm_tg_right_bottom {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
}