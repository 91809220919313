.iask-paper-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  overflow: hidden;
}

.iask-paper-list {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.iask-paper-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  box-sizing: border-box;
}

.iask-paper-question-inner{
  display: flex;
  padding: 10% 15%;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow-y: auto;
}

.iask-paper-big-title {
  font-size: 84px;
  line-height: 108px;
  color: rgba(255, 255, 255, 0.7);
}

.iask-paper-sub-title {
  font-size: 18px;
  line-height: 48px;
  color: rgba(255, 255, 255, 0.7);
}

.iask-paper-go {
  color: #6992cb;
  font-size: 16px;
  font-weight: 700;
  border-radius: 100px;
  margin-top: 30px;
  /* text-transform: uppercase; */
  overflow: hidden;
  min-width: 150px;
  display: flex;
  align-items: center;
  height: 54px;
  border: 2px solid #6992cb;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}

.iask-paper-go svg {
  margin-left: 5px;
}

.iask-paper-go svg path {
  stroke: currentColor;
  fill: currentColor;
}
.iask-paper-question-num{
  width: 100%;
  font-size: 16px;
  color: rgb(172, 172, 172);
  line-height: 24px;
  margin-bottom: 10px;
  text-align: left;
}
.iask-paper-question-title{
  width: 100%;
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
  text-align: left;
}
ul.iask-paper-select-ul {
  display: flex;
  flex-wrap: wrap;
}

.iask-paper-select-li {
  width: 200px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin: 0 24px 24px 0;
}

.iask-paper-select-li-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 146px;
}

.iask-paper-select-li-img img {
  max-width: 100%;
  max-height: 100%;
}

.iask-paper-qt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 5px 10px;
  color: #fff;
  word-break: break-all;
  text-align: left;
  line-height: 18px;
}

.iask-paper-ql {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #393939;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.7);
  margin-left: 10px;
}

.slideDown {
  animation: slideDown ease .3s backwards;
}

.slideUp {
  animation: slideUp ease .3s backwards;
}









@keyframes slideDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes slideDown2 {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}

.iask-paper-under-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  box-sizing: border-box;
}

.iask-paper-under-line-right {
  color: #fff;
  display: flex;
  font-size: 28px;
}
.iask-paper-up,.iask-paper-down{
  cursor: pointer;
}
.iask-paper-up.disabled,.iask-paper-down.disabled{
  color: #666;
  cursor: not-allowed;
}

/* 向上 */
.flideUp-enter {
  transform: translateY(100%);
}

.flideUp-enter.flideUp-enter-active {
  transform: translateY(0);
  transition: all 500ms ease-in-out;
}

.flideUp-leave {
  transform: translateY(-100%);
}

.flideUp-leave.flideUp-leave-active {
  transition: all 500ms ease;
  transform: translateY(-200%);
}
/* 向下 */
.flideDown-enter {
  transform: translateY(-100%);
}

.flideDown-enter.flideDown-enter-active {
  transform: translateY(0);
  transition: all 500ms ease-in-out;
}

.flideDown-leave {
  transform: translateY(-100%);
}

.flideDown-leave.flideDown-leave-active {
  transition: all 500ms ease;
  transform: translateY(0);
}

/* 屏幕小于 750px */
@media screen and (width <=750px) {
  .iask-paper-big-title {
    font-size: 36px;
    line-height: 48px;
  }
}