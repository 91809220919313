.w1_center_phone {
    width: 375px;
    margin-top: 24px;
    top: 0;
    bottom: 20px;
    /* min-height: 400px; */
    position: absolute;
    user-select: none;
}
.has_no_save_data.w1_center_phone{
    top: 60px;
}
.w1_nav{
    width: 375px;
    height: 64px;
    background: url(../../../../assets/images/other/nav.png) no-repeat;
    position: absolute;
    left: 0;
    top: 40px;
    border-bottom: 1px solid #f4f4f4;
    box-sizing: border-box;
}
.w1_time{
    color: #111111;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
}
.w1_title{
    font-size: 17px;
    color: #111111;
    text-align: center;
    line-height: 44px;
    margin-top: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
    margin: 0 auto;
}
.w1_center_phone .zent-tabs{
    height: 100%;
}
.w1_center_phone .zent-tabs-nav.zent-tabs-nav-type__card {
    background-color: #f7f8fa;
}

.w1_center_phone .zent-tabs-panel-wrapper {
    margin-top: 64px;
    background-color: #fff;
    position: absolute;
    top: 40px;
    bottom: 0;
}

.w1_center_phone .zent-tabs-nav-content {
    flex-grow: 0;
}

.w1_center_phone .zent-tabs-nav-extra-content {
    width: 40px;
    height: 40px;
    justify-content: center;
    margin-left: 0;
    border-width: 1px;
    border-style: solid;
    border-color: var(--theme-stroke-5, #dcdee0);
    cursor: pointer;
    flex-shrink: 0;
    margin-left: -1px;
    box-sizing: border-box;
}

.w1_center_phone i.zenticon.zenticon-plus.zent-tabs-add-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w1_center_phone .zent-tabs-nav-tabs-content-anchor {
    width: initial;
    flex-grow: 0;
}

.w1_center_phone .zent-tabs-nav-tabs-content-anchor-main .zent-tabs-tab.zent-tabs-tab-type__card:first-child .zent-tabs-tab-delete, .w1_center_phone .zent-tabs-nav-tabs-content-anchor-main .zent-tabs-tab.zent-tabs-tab-type__card:last-child .zent-tabs-tab-delete {
    display: none;
}

.w1_center_phone .zent-tabs-panel {
    overflow: hidden;
    width: 450px;
    height: 100%;
}

.w1_center_phone .page_content_wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    width: 500px;
    height: 100%;
}

.w1_center_phone .page_content {
    width: 375px;
    min-height: 100%;
    padding-bottom: 48px;
    box-sizing: border-box;
}

.put_area {
    display: none;
    position: absolute;
    left: 0;
}

.can_not_put {
    display: none;
    position: absolute;
    left: 0;
    cursor: url(../../../../assets/images/other/disabled.ico), auto;
    z-index: 12;
}
.can_not_put img{
    -webkit-user-drag: none;
}
.preview_module_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 13px;
    position: relative;
    user-select: none;
    transition: .5s ease;
}

.preview_mask {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 3px solid #155BD4;
    box-sizing: border-box;
    z-index: 6;
}

.preview_mask.active {
    display: block;
}

.preview_module_wrapper:hover .preview_mask {
    display: block;
    border: 1px dashed #155BD4;
}

.preview_module_wrapper:hover .preview_mask.active {
    border: 3px solid #155BD4;
}

.preview_del {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    height: 28px;
    align-items: center;
    width: 40px;
    justify-content: center;
    background-color: #fff;
    box-sizing: border-box;
}

.can_not_delete{
    color: #D3D3D3;
    cursor: url(../../../../assets/images/other/disabled.ico), auto !important;
}

.preview_del:hover {
    border: 1px solid #155BD4;
    cursor: pointer;
}

.preview_tooltip {
    position: absolute;
    top: 0;
    left: 102%;
}

.preview_tooltip_text {
    position: absolute;
    left: 0;
    top: 0;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    background-color: #fff;
    border-radius: 2px;
    white-space: nowrap;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, .3);
    z-index: 2;
}

.left_triangle {
    display: block;
    width: 4px;
    height: 4px;
    background: #fff;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, .3);
    position: absolute;
    left: -2px;
    z-index: 1;
    top: 10px;
    transform: rotate(45deg);
}

.preview_module_wrapper:hover .preview_tooltip {
    display: none;
}

.preview_module_wrapper:hover .preview_del {
    display: flex;
}
.preview_module_wrapper .preview_tooltip.hide,.preview_module_wrapper .preview_del.hide{
    display: none;
}

.preview_name_line {
    font-size: 16px;
    color: #323233;
    line-height: 22px;
    margin-bottom: 4px;
    text-align: justify;
    display: flex;
    word-break: break-all;
    padding-right: 12px;
}
.preview_text_name_line{
    font-size: 16px;
    color: #323233;
    line-height: 22px;
    margin-bottom: 4px;
    text-align: left;
    word-break: break-all;
}
.require_wrapper{
    width: 10px;
    height: 10px;
    margin-top: 6px;
    margin-right: 3px;
    flex-shrink: 0;
}
.require_wrapper span{
    display: block;
    width: 10px;
    height: 10px;
    line-height: 14px;
}
.w1h20{
    width: 1px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
}
.thumbSrc_wrapper{
    display: flex;
    width: 303px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px;
    padding-bottom: 11px;
}
.thumbSrc_wrapper img{
    max-width: 100%;
    max-height: 100%;
}
.jump_tips {
    color: #ED6A0C;
    text-align: left;
    white-space: nowrap;
}

.preview_des {
    color: #969799;
    line-height: 18px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 22px;
    text-align: justify;
    word-break: break-all;
    margin: 0 12px;
}

.option_group_wrapper {
    display: flex;
    border: 1px solid #ebedf0;
    border-radius: 4px;
    margin: 12px 11px 0;
}

.option_wrapper {
    width: 100%;
    border-bottom: 1px solid #ebedf0;
    box-sizing: border-box;
}

.option_wrapper:last-child {
    border-bottom: 0;
}

.option_group_wrapper .zent-radio-group,.option_group_wrapper .zent-checkbox-group {
    width: 100%;
    text-align: left;
}

.option_title {
    display: flex;
    flex-wrap: wrap;
    min-height: 20px;
    padding-left: 16px;
}

.option_wrapper label.zent-radio-wrap,.option_wrapper label.zent-checkbox-wrap {
    display: flex;
    margin-right: 0;
    overflow: hidden;
    margin-left: -16px;
    padding: 11px;
    width: 100%;
}

.option_wrapper label.zent-radio-wrap span.zent-radio-label {
    word-break: break-all;
    width: 100%;
    line-height: 16px;
    text-align: justify;
}
.option_wrapper .zent-checkbox-label{
    text-align: justify;
}
.juan_set {
    position: absolute;
    height: 32px;
    width: 94px;
    right: 12px;
    top: 24px;
    background-color: #155bd4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    border-radius: 2px;
    font-size: 12px;
}
.has_no_data.juan_set{
    top: 84px;
}

.juan_set img {
    margin-right: 3px;
}

.zent-popover-v2.zent-popover-v2-position-bottom-left{
    z-index: 10;
    margin-top: -3px;
}
.option_wrapper .other_radio_wrapper span.zent-radio-label{
    white-space: initial !important;
}
.option_wrapper span.zent-checkbox{
    margin-top: 2px;
}
.other_wrapper{
    display: flex;
}
.other_name{
    display: flex;
    margin-right: 8px;
    white-space: nowrap;
}
.other_content{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-decoration: underline;
    word-break: break-all;
}

.checkbox_tips{
    margin-left: 5px;
    color: #9b9b9b;
}

.mobile_input input.zent-input{
    background: url(../../../../assets/images/icons/phone.png) 8px center no-repeat;
    background-size: 20px;
    padding-left: 32px !important;
}

.mail_input input.zent-input{
    background: url(../../../../assets/images/icons/email.png) 8px center no-repeat;
    background-size: 20px;
    padding-left: 32px !important;
}

.date_input input.zent-input{
    background: url(../../../../assets/images/icons/date.png) 8px center no-repeat;
    background-size: 20px !important;
}
.area_input input.zent-input{
    background: url(../../../../assets/images/icons/area.png) 8px center no-repeat;
    background-size: 20px;
    padding-left: 32px !important;
}

.preview_module_wrapper.text_preview{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 15.5px;
}
.preview_module_wrapper.text_preview.full{
    padding: 0;
}
.preview_module_wrapper.text_preview.center{
    flex-direction: column;
    align-items: center;
}

.preview_text_line{
    text-align: left;
    line-height: 20px;
    word-break: break-all;
}

.preview_module_wrapper.text_preview.center .preview_text_name_line,.preview_module_wrapper.text_preview.center .preview_text_line{
    text-align: center;
}
.preview_module_wrapper.text_preview.right .preview_text_name_line,.preview_module_wrapper.text_preview.right .preview_text_line{
    text-align: right;
}

.show_more{
    height: 22px;
    line-height: 22px;
    margin: 0 5px 0 10px;
    white-space: nowrap;
    cursor: pointer;
}

.show_more_style_1{
    color: #38f;
}
.show_more_style_1 span{
    display: none;
}
.show_more_style_2{
    display: flex;
    color: #969799;
}
.show_more_style_2 span{
    display: flex;
    align-items: center;
}

.show_more_style_3 p{
    display: none;
}
.show_more_style_3 span{
    color: #969799;
    display: inline-block;
}

.wx_text_line{
    color: #969799;
    word-break: break-all;
}

.wx_text_line span{
    margin-right: 10px;
}
.link_name{
    color: #607fa6;
}

.text_preview .title_wrapper{
    width: 100%;
}

.end_wrapper.preview_module_wrapper{
    padding: 13px 0;
}
.end_inner{
    padding: 23px 13px 20px;
}

.end_success_title{
    font-size: 18px;
    margin: 12px auto 36px;
    word-break: break-all;
}

.end_btns{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 250px;
    margin: 0 auto;
}
.end_btn{
    white-space: nowrap;
    line-height: 34px;
    height: 36px;
    box-sizing: border-box;
    padding:0 16px;
    border: 1px solid #dcdee0;
    border-radius: 18px;
    max-width: 215px;
    margin: 0 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.prize_preview{
    display: flex;
    flex-direction: column;
    border-top: 1px solid #dcdee0;
}
.pp_title{
    color: #ffad22;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
}

.pp_list{
    display: flex;
    justify-content: center;
    padding: 0 13px;
    box-sizing: border-box;
}
.pp_list.has_1{
    width: 100%;
}
.pp_list.has_1 .pp_one{
    width: 100%;
    flex-direction: row;
    height: 88px;
}
.pp_list.has_1 .pp_left,.pp_list.has_4 .pp_one:nth-child(4) .pp_left{
    flex-direction: row;
    
}
.pp_list.has_1 .pp_l_img,.pp_list.has_4 .pp_one:nth-child(4) .pp_l_img{
    width: 48px;
    height: 48px;
    margin-right: 10px;
}
.pp_list.has_1 .pp_l_r,.pp_list.has_4 .pp_one:nth-child(4) .pp_l_r{
    margin: 0;
}
.pp_list.has_1 .pp_name,.pp_list.has_4 .pp_one:nth-child(4) .pp_name{
    text-align: left;
}
.pp_list.has_1 .pp_content,.pp_list.has_4 .pp_one:nth-child(4) .pp_content{
    max-width: 179px;
}

.pp_list.has_4{
    flex-wrap: wrap;
}
.pp_list.has_4 .pp_one:nth-child(3){
    margin-right: 0;
}
.pp_list.has_4 .pp_one:nth-child(4){
    margin: 16px;
    width: 100%;
    flex-direction: row;
    height: 88px;
}


.pp_one{
    width: 94px;
    height: 120px;
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff5e2;
    border-radius: 6px;
    justify-content: space-between;
    margin-right: 16px;
}
.pp_one:last-child{
    margin-right: 0;
}
.pp_left{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pp_l_img{
    width: 30px;
    height: 30px;
    display: block;
}
.pp_l_r{
    text-align: center;
    margin-top: 4px;
    margin-bottom: 8px;
}
.pp_name{
    color: #A25700;
    line-height: 20px;
}
.pp_content{
    color: #FFAD22;
    font-size: 12px;
    line-height: 18px;
    max-width: 86px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.preview_scan{
    width: 64px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    background-color: #ffad22;
    border-radius: 12px;
    font-size: 12px;
}



/* preview */
.preview_pre{
    width: 100%;
    text-align: left;
}
.preview_pre table{
    margin-bottom: 10px;
    border-collapse: collapse;
    width: 100%;
}
.preview_pre td, .preview_pre th {
    padding: 5px 10px;
    border: 1px solid #DDD;
}
.preview_pre p{
    margin: 0;
    line-height: initial;
    word-break: break-all;
    white-space: initial;
}
.preview_pre img{
    display: block;
    max-width: 100%;
}

.cap-richtext {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    color: #333;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    word-wrap: break-word;
}
.cap-richtext p {
    margin: 0 0 1em;
}
.cap-richtext em, .cap-richtext i {
    font-style: italic;
}
.cap-richtext b, .cap-richtext strong {
    font-weight: 700;
}
.cap-richtext table {
    display: table;
    width: auto!important;
    margin: 0 auto 10px;
    border-collapse: collapse;
}
.cap-richtext td, .cap-richtext th {
    padding: 5px 10px;
    border: 1px solid #ddd;
}

.mt50{
    margin-top: 48px;
}
.mb50{
    margin-bottom: 48px;
}
.preview_input_wrapper{
    border-radius: 4px;
    margin: 0 11px;
}
.preview_input_wrapper textarea.zent-textarea{
    resize: initial;
}

.no_save_data_tips{
    margin: 12px 12px 0;
    border: 1px solid #94b4eb;
    background-color: #edf4ff;
    border-radius: 2px;
    height: 48px;
    box-sizing: border-box;
    line-height: 46px;
    align-self: stretch;
    padding-left: 16px;
}

.no_save_data_content{
    font-size: 14px;
    margin-left: 5px;
}
.no_save_data_left{
    display: flex;
    align-items: center;
}

.no_save_data_btn{
    margin:0 5px;
    cursor: pointer;
    color: #0057e4;
}

.close_no_save_data_tips{
    float: right;
    font-size: 24px;
    width: 46px;
    height: 46px;
    cursor: pointer;
}
.no_save_icon{
    color: #155BD4;
}

.control_info{
    font-size: 14px;
    color: #999;
    line-height: 28px;
    margin-top: 15px;
    width: 188px;
    text-align: left;
    padding: 15px;
    box-sizing: border-box;
}

/* 滚动按钮 */
.scroll_btns{
    position: absolute;
    width: 32px;
    margin-left: 560px;
    bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #5c5c66;
}
.scroll_up{
    transform: rotate(-90deg);
}
.scroll_down{
    transform: rotate(90deg);
}
.scroll_item:hover{
    background-color: rgba(0, 0, 0, .1);
}
.scroll_item img{
    display: block;
    width: 32px;
    height: 32px;
}
/* 滚动按钮 end*/