.wj_wrapper_container{
    /* padding:16px 16px 0; */
    height: 100%;
    box-sizing: border-box;
    background-color: #f8f8f8;
}
.wj_wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height:100%;
    background-color: #fff;
    padding: 16px 13px;
    box-sizing: border-box;
}
.wj_title_1{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 26px;
    color: #323233;
    text-align: left;
    margin-bottom: 14px;
    box-sizing: border-box;
}
.wj_search{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.wj_search_right{
    display: flex;
}
.wj_select{
    margin-right: 16px;
}
.wj_table_wrapper{
    height: 100%;
    flex-shrink: 1;
    overflow: hidden;
}

.zenticon-search:before{
    font-size: 24px;
}

.iask-right-bar{
    display: flex;
    font-size: 12px;
}

.iask-back-home{
    cursor: pointer;
}