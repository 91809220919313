.w1_right_title{
    width: 100%;
    line-height: 40px;
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #979797;
    font-size: 18px;
    color: #323233;
    flex-shrink: 0;
    text-align: left;
}
.image_title_des{
    font-size: 12px;
    color: #9ea2a5;
    line-height: 20px;
    text-align: left;
}
.w1_right_content{
    width: 100%;
    padding: 16px;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}
.w1_right_hidden{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}
.w1_right_scroll{
    height: 100%;
    width: 384px;
    overflow-x: auto;
}
/* base */
.w1_base{
    width: 344px;
    display: flex;
    padding-top: 8px;
    flex-direction: column;
}
/* .w1_base .date_range{
    display: flex;
    flex-direction: column;
} */
.date_range.dist .zent-datepicker-trigger{
    border-color: var(--theme-stroke-5, #D40000);
}
.date_range.dist.fill .zent-datepicker-trigger{
    border-color: var(--theme-stroke-5, #dcdee0);
}

.w1_right .zent-radio-group{
    width: 100%;
    text-align: left;
}

/* 通设 */
.w1_form_item{
    position: relative;
    margin-bottom: 16px;
}

.name_line{
    display: flex;
    align-items: center;
    height: 32px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 8px;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between;
    color: #9FA1A4;
}
.name_line span{
    margin-left: 5px;
    margin-right: 5px;
}
.sameline{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sameline .name_line{
    margin-bottom: 0;
}
.sameline .zent-radio-group{
    text-align: right;
}

.require {
    color: #D40000;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    display: none;
}

.dist .require{
    display: block;
}
.dist .zent-input-wrapper.zent-input--size-normal,.dist .zent-select-v2{
    border-color: #D40000;
}
.dist.fill .zent-input-wrapper.zent-input--size-normal,.dist.fill .zent-select-v2{
    border-color: var(--theme-stroke-5, #dcdee0);
}
.dist.fill .require{
    display: none;
}
.notice{
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}
.notice_link{
    color: #155BD4;
    cursor: pointer;
}
.show .notice{
    display: block;
}
.zent-tooltip-v2 .zent-tooltip-v2-inner{
    background-color: #fff !important;
    color: #333 !important;
}
.zent-tooltip-v2 .zent-tooltip-v2-arrow{
    background-color: #fff !important;
}

.w182{
    width: 182px !important;
}
.option_inner_wrapper{
    background: #fff;
    padding: 12px;
    position: relative;
}
.option_inner_wrapper .w1_form_item:last-child{
    margin-bottom: 0;
}
.option_del_btn{
    display: none;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.option_inner_wrapper:hover .option_del_btn{
    display: block;
}
.w1_option_top{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #fff;
    padding-bottom: 16px;
}
.w1_form_item .zent-image-upload.zent-image-upload-demo{
    display: flex;
}
.w1_form_item .zent-image-upload-tips{
    width: 115px;
    margin-left: 5px;
}
.w1_option_item{
    padding: 12px;
    background-color: #f7f8fa;
}

.rfs0{
    flex-shrink: 0;
}

.upload_wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.upload_wrapper li.zent-image-upload-item{
    margin-right: 0;
}

.right_hidden{
    display: none;
}

.w1_form_item.w1_option_item{
    margin-bottom: 0;
    padding-bottom: 0;
}
.w1_form_item.w1_option_item:last-child{
    padding-bottom: 12px;
}

.hidden_module{
    display: none;
}

.add_btn{
    padding: 12px;
    background: #f7f8fa;
    display: flex;
    flex-direction: column;
}
.add_btn.first_btn{
    padding-bottom: 0;
}
.add_btn button.zent-btn{
    width: 100%;
    color: #155BD4;
}
.add_btn .zent-btn-group{
    margin-bottom: 12px;
}
.add_btn .zent-btn-group:last-child{
    margin-bottom: 0;
}
.add_btn i.zenticon.zenticon-plus{
    display: flex;
    align-items: center;
    float: left;
    height: 100%;
    font-weight: bold;
}

.power_title{
    display: flex;
}

.single_line {
    line-height: 20px;
    text-align: left;
    padding: 5px;
    color: #9FA1A4;
}

.day_times{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.day_times span{
    white-space: nowrap;
    flex-shrink: 0;
}
.join_times label.zent-radio-wrap{
    display: flex;
    height: 32px;
    line-height: 32px;
    align-items: center;
}
.day_times input.zent-input{
    width: 80px;
}
.join_day_wrapper,.join_times_wrapper{
    position: relative;
    margin:0 5px;
}
.join_times_msg{
    position: absolute;
    left: 0;
    top: 32px;
    white-space: nowrap;
}

.give_obj_line{
    display: flex;
    min-height: 32px;
    line-height: 32px;
    align-items: center;
    justify-content: space-between;
}
.give_obj_line .w1_form_item{
    margin-bottom: 0;
}
.give_obj_line .name_line{
    display: none;
}

.choose_prize_wrapper .zent-checkbox-group{
    width: 100%;
}
.choose_prize_checkbox{
    display: flex;
    flex-direction: column;
}
.choose_prize_checkbox label.zent-checkbox-wrap{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    margin-right: 0;
}

.choose_prize_checkbox .zent-checkbox-label{
    margin-left: 5px;
}

.prize_score{
    margin-top: 8px;
}

.power_tip{
    display: flex;
    align-items: center;
}

.prize_select{
    display: flex;
    flex-direction: column;
    background: #fff;
    box-sizing: border-box;
    z-index: 2;
    text-align: left;
    cursor: pointer;
    margin-bottom: 10px;
}
.prize_select li{
    padding: 4px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 5px;
}
.prize_select li:hover{
    background-color: var(--theme-primary-8, #edf4ff);
}

.prize_content_wrapper {
    position: relative;
}

.prize_content {
    padding: 0 36px;
    line-height: 40px;
    background: #fff;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.prize_del {
    position: absolute;
    right: -8px;
    top: -8px;
    color: #C8C9CC;
    cursor: pointer;
}

.prize_del i.zenticon.zenticon-close-circle{
    font-size: 18px;
}

.w1_option_top li.zent-image-upload-item{
    background: rgba(0,0,0,.1);
}
.zent-datepicker{
    width: 100%;
}

.text_line label.zent-radio-button{
    vertical-align: middle;
}
.text_line .name_line{
    justify-content: left;
}
.line_value{
    color: #323233;
    font-weight: 500;
}
.color_wrapper{
    display: flex;
}
.reset_color{
    white-space: nowrap;
    margin-right: 5px;
    cursor: pointer;
    line-height: 30px;
}

.assist_line{
    background-color: rgb(235, 237, 240);
    height: 1px;
    padding: 0 0 16px;
    background-clip: content-box;
}

.more_style{
    margin-bottom: 16px;
}
.edui-default .edui-editor{
    box-sizing: border-box;
}

/* 弹窗 */
.choose_dialog{
    position: fixed;
    width: 200px;
    height: 400px;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -200px;
    z-index: 11;
    background: #fff;
}
