.wd_controls{
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding-right: 25px;
}
.wd_controls span{
    color: #155BD4;
    padding: 0 5px;
    border-right: 1px solid #c8c9cc;
    cursor: pointer;
}
.wd_controls span:last-child{
    border-right: 0;
}
.toggle_box{
    display: none;
    position: absolute;
    right: 0;
    width: 88px;
    height: 66px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(200,201,204,.5);
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 2;
    top: 25px;
    right: -10px;
}
.toggle_box::after{
    display: block;
    content: "";
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    top: -6px;
    left: 50%;
    margin-left: -7px;
}
.wd_dots:hover .toggle_box{
    display: flex;
}
.toggle_box span{
    border-right: 0;
}
.wrapLine{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
}
.row_status{
    color:#c8c9cc;
}

.downloadQrcode{
    margin-top: 15px;
    line-height: 36px;
    cursor: pointer;
}