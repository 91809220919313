.w2_container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.w2_header {
    height: 56px;
    padding: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 11;
}

.w2_h_center {
    width: 375px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.w2_h_center_left {
    line-height: 30px;
    height: 30px;
    box-sizing: border-box;
    display: flex;
}

.phone_mode {
    margin-left: 20px;
    border-bottom: 2px solid #155bd4;
}

.w2_content {
    height: 100%;
    flex-shrink: 1;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    position: relative;
}

.w2_phone_area {
    width: 375px;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}


.preview_top_tips {
    height: 40px;
    line-height: 40px;
    background-color: #fffbe8;
    color: #ED6A0C;
    text-align: center;
    flex-shrink: 0;
}


.w2_phone_wrapper {
    width: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    background-color: #fff;
    /* background-color: #dcdee0; */
}
.w2_phone_content{
    width: 375px;
    min-height: 100%;
}

/* w2_preview_end */
.w2_preview_end{
    position: relative;
    width: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}
.w2_end_btns{
    display: flex;
    justify-content: space-between;
    padding: 20px 25px 0;
}
.w2_end_btn{
    min-width: 158px;
    height: 48px;
    line-height: 46px;
    border-radius: 24px;
    background-color: #1989fa;
    border: 1px solid #1989fa;
    color: #fff;
    cursor: pointer;
    flex-grow: 1;
    margin-right: 12px;
}
.page_up{
    background-color: #fff;
    color: #323233;
    border: 1px solid #dcdee0;
}
.w2_end_btn:last-child{
    margin-right: 0;
}
.w2_end_ico{
    height: 66px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #c8c9cc;
    justify-content: center;
}
.w2_end_ico_top{
    font-size: 16px;
    line-height: 24px;
}
.w2_end_ico_bottom{
    font-size: 12px;
    line-height: 16px;
}
/* w2_preview_end */

.w2_module_box{
    background-color: #fff;
}
/* w2_cascader_wrapper */
.w2_cascader_wrapper{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 21;
    display: none;
}
.w2_cascader_wrapper.active{
    display: block;
}
.bg{
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.5);
}
.w2_popover_box{
    position: absolute;
    width: 100%;
    height: 480px;
    box-sizing: border-box;
    transition: .5s ease;
    bottom: -480px;
    background: #fff;
    border-radius: 20px 20px 0 0;

}
.w2_popover_box.active{
    bottom: 0;
}
.w2c_title_line{
    display: flex;
    justify-content: space-between;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    font-size: 16px;
    align-items: center;
}
.w2c_tabs{
    display: flex;
    height: 48px;
    position: relative;
    padding: 0 6px;
    margin-bottom: 6px;
}
.w2c_active_line {
    width: 40px;
    height: 3px;
    border-radius: 1.5px;
    background: #1989fa;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: .5s ease;
}
.w2c_tab_tag{
    height: 48px;
    line-height: 48px;
    margin: 0 10px;
    color: #969799;
    max-width: 100px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.w2c_tab_tag span{
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -20px;
    display: block;
    visibility: hidden;
    width: 40px;
    height: 0;
}
.w2c_tab_tag.has_val{
    color: #323233;
}
.w2c_scroll_box{
    width: 400px;
    height: 378px;
    overflow-y: auto;
    overflow-x: hidden;
}

.w2c_tab_content{
    width: 300%;
    display: flex;
    transition: .5s ease;
}
.w2c_tab_content.active_0{
    transform: translateX(0);
}
.w2c_tab_content.active_1{
    transform: translateX(-375px);
}
.w2c_tab_content.active_2{
    transform: translateX(-750px);
}

.w2c_tab_content_item{
    width: 375px;
    overflow: hidden;
}
.w2c_tab_content_item ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 38px;
    cursor: pointer;
}
.w2c_tab_content_item ul li.active{
    color: #1989fa;
}

.w2_close_cascader{
    height: 100%;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.w2_mobile_input{
    height: 30px;
    line-height: 28px;
    box-sizing: border-box;
    border: 1px solid var(--theme-stroke-5, #dcdee0);
    background: url(../../assets/images/icons/phone.png) 8px center no-repeat;
    background-size: 20px;
    padding-left: 32px !important;
    text-align: left;
    cursor: pointer;
}
.w2_date_input{
    height: 30px;
    line-height: 28px;
    box-sizing: border-box;
    border: 1px solid var(--theme-stroke-5, #dcdee0);
    background: url(../../assets/images/icons/date.png) 8px center no-repeat;
    background-size: 20px;
    padding-left: 32px !important;
    text-align: left;
    cursor: pointer;
}
.w2_area_input{
    height: 30px;
    line-height: 28px;
    box-sizing: border-box;
    border: 1px solid var(--theme-stroke-5, #dcdee0);
    background: url(../../assets/images/icons/area.png) 8px center no-repeat;
    background-size: 20px;
    padding-left: 32px !important;
    text-align: left;
    cursor: pointer;
}
.w2_date_title{
    width: 100%;
}
.w2_ensure_date{
    width: 48px;
    color: #1989fa;
    cursor: pointer;
}

/* dateCascader */
.w2_date_popover{
    height: 352px;
}
.w2c_scroll_date_box{
    width: 125px;
    height: 270px;
    overflow: hidden;
}
.w2c_date_wrapper{
    width: 160px;
    height: 270px;
    overflow: hidden;
}

.w2c_scroll_date_box ul{
    width: 125px;
}
.w2c_scroll_date_box ul li{
    height: 36px;
    line-height: 36px;
    font-size: 14px;
}
.w2c_scroll_date_box ul li.active{
    height: 48px;
    line-height: 48px;
    user-select: none;
    font-size: 18px;
}
.w2c_scroll_box ul{
    width: 375px;
}

/* 引入日期组件修改样式 */
.Modal-Portal {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 0;
    left: 50%;
    top: 56px;
    bottom: 0;
}
.Modal-Portal .datepicker-modal {
    position: absolute;
    top: 0;
    right: initial;
    bottom: 0;
    width: 375px;
    background-color: rgba(0, 0, 0, .6);
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.datepicker .datepicker-content{
    padding: 0 20px !important;
}
.datepicker .datepicker-col-1{
    margin: 0 !important;
}
.datepicker .datepicker-scroll>li{
    font-size: 15px !important;
    color: #646566 !important;
}
.datepicker.default .datepicker-wheel{
    border-top: 1px solid #979797 !important;
    border-bottom: 1px solid #979797 !important;
}
.datepicker .datepicker-viewport{
    height: 270px !important;
}
.datepicker.default .datepicker-header{
    height: 48px;
    line-height: 48px;
    color: #323233 !important;
}
.datepicker.default {
    padding-bottom: 34px;
    overflow: hidden;
    background-color: #fff !important;
    bottom: -352px;
    transition: .5s ease;
}
.datepicker:after{
    display: block;
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    bottom: 34px;
    left: 0;
    box-shadow: 0 -10px 10px 16px rgba(255,255,255,.95);
}
.datepicker.default .datepicker-navbar{
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0;
    box-shadow: 0 10px 10px 16px rgba(255,255,255,.95);
}
.datepicker.default .datepicker-navbar-btn:first-child{
    color: #1989fa !important;
}
.datepicker.default .datepicker-navbar-btn:last-child{
    color: #969799 !important;
}
.datepicker .datepicker-navbar-btn{
    height: 48px !important;
    line-height: 48px !important;
}

.preview_regex_check_tips {
    height: 35px;
    line-height: 35px;
    text-align: left;
    color: #fa1919;
    background: #ffebeb;
    border-radius: 4px;
    margin: 8px 11px 0;
    padding: 0 10px;
}
.only_line{
    width: 54px;
    height: 31px;
    box-sizing: border-box;
    border-bottom: 1px solid #dcdee0;
}
.other_div{
    min-width: 54px;
    line-height: 20px;
    box-sizing: border-box;
    word-break: break-all;
    margin-right: 11px;
    -webkit-user-modify: read-write;
    width: 100%;
    text-decoration: underline;
    padding: 11px 0;
    outline: none;
}
.other_radio_wrapper.option_title{
    flex-wrap: initial;
}
.other_radio_wrapper label.zent-radio-wrap,.other_radio_wrapper label.zent-checkbox-wrap{
    width: 48px;
    flex-shrink: 0;
}

.preview_in_mobile{
    position: absolute;
    left: 50%;
    margin-left: 200px;
    top: 160px;
    width: 176px;
    padding: 8px;
    box-sizing: border-box;
    background-color: #fff;
}
.preview_in_mobile p{
    line-height: 20px;
    border-bottom: 1px dashed #EBEDF0;
}
.preview_in_mobile img{
    width: 140px;
    margin:0 auto;
}

/* 在手机上 */
.inPhone .w2_phone_area{
    width: 100%;
}

.inPhone .w2_phone_wrapper{
    width: 100%;
}
.inPhone .w2_phone_content{
    width: 100%;
}
/* 答题模式 */
.answer .preview_top_tips{
    display: none;
}

.answer .w2_header{
    display: none;
}