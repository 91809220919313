.iask-home-wrapper{
  position: relative;
  overflow-x: hidden;
}
.iask-home-header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 10;
}

.iask-header-center{
  width: 1234px;
  height: 100px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  justify-content: space-between;
  margin: 0 auto;
}

.iask-header-center-left{
  display: flex;
}

.iask-logo{
  display: flex;
  align-items: center;
}

.iask-logo img{
  display: block;
  margin-top: -13px;
}


.iask-service{
  display: flex;
  margin-left: 35px;
}
.iask-service a{
  display: block;
  height: 100px;
  line-height: 100px;
  margin-left: 15px;
  color: #fff;
}

.iask-header-center-right {
  display: flex;
  height: 100%;
  align-items: center;
}

.iask-manage-center{
  display: flex;
  align-items: center;
  color: #2381db;
  cursor: pointer;
}

.iask-login,.iask-register{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #2381db;
  color: #2381db;
  border-radius: 4px;
  margin-left: 12px;
  cursor: pointer;
}

.iask-register{
  background-color: #2381db;
  color: #fff;
}

.iask-banner-center{
  width: 1234px;
  margin: 0 auto;
  position: relative;
}

.iask-banner-bg{
  display: block;
  margin-left: -343px;
}

.iask-layers{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@keyframes scale1 {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleAndRotate {
  from {
    transform: scale(0);
  }
  40% {
    transform: scale(1) rotateY(0deg);
  }
  50%{
    transform: rotateY(60deg);
  }
  60%{
    transform: rotateY(120deg);
  }
  70%{
    transform: rotateY(180deg);
  }
  80%{
    transform: rotateY(240deg);
  }
  90%{
    transform: rotateY(300deg);
  }
  100%{
    transform: rotateY(360deg);
  }
}

@keyframes scaleAndRotateDesc {
  from {
    transform: scale(0);
  }
  30% {
    transform: scale(1) rotateY(0deg);
  }
  40%{
    transform: rotateY(-120deg);
  }
  50%{
    transform: rotateY(-220deg);
  }
  60%{
    transform: rotateY(-320deg);
  }
  70%{
    transform: rotateY(-420deg);
  }
  80%{
    transform: rotateY(-520deg);
  }
  90%{
    transform: rotateY(-620deg);
  }
  100%{
    transform: rotateY(-720deg);
  }
}
@keyframes flyDown {
  from {
    transform: translateY(-1000px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes showAndRotate {
  from {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
  }
}


.iask-banner-item{
  position: absolute;
  left: 50%;
  display: block;
  z-index: 2;
}
.iask-banner-item1{
  top: 610px;
  margin-left: -402px;
  animation: scale1 .5s ease both;
}
.iask-banner-item2{
  top: 531px;
  margin-left: -330px;
  animation: scale1 .5s ease .1s both;
}
.iask-banner-item3{
  top: 493px;
  margin-left: -285px;
  animation: scale1 .5s ease .2s both;
}
.iask-banner-item4{
  top: 527px;
  margin-left: -247px;
  animation: scale1 .5s ease .3s both;
}
.iask-banner-item5{
  top: 374px;
  margin-left: -348px;
  animation: scale1 .5s ease .4s both;
}
.iask-banner-item6{
  top: 513px;
  margin-left: -131px;
  animation: scaleAndRotate 3s linear 2.5s both;
}
.iask-banner-item7{
  top: 477px;
  margin-left: 23px;
  animation: scaleAndRotateDesc 3s linear 1.5s both;
}
.iask-banner-item8{
  top: 478px;
  margin-left: 193px;
  animation: flyDown 1s ease-in-out .6s both;
}
.iask-banner-item9{
  top: 388px;
  margin-left: -378px;
  animation: showAndRotate 1s ease-in-out .7s both;
}
.iask-banner-item10{
  top: 304px;
  margin-left: -18px;
  animation: showAndRotate 1s ease-in-out 1.2s both;
}
.iask-banner-item11{
  top: 391px;
  margin-left: 368px;
  animation: showAndRotate 1s ease-in-out 1.7s both;
}

.iask-part{
  background-color: #fff;
}
.iask-part-center{
  width: 1234px;
  padding-top: 84px;
  padding-bottom: 96px;
  margin: 0 auto;
}

.iask-part-title{
  line-height: 60px;
  height: 60px;
  font-size: 40px;
  color: #4da2f3;
  text-align: center;
  font-weight: bold;
}

.iask-part-sub-title{
  color: #3a3a3a;
  line-height: 34px;
  font-size: 14px;
  text-align: center;
  margin-bottom: 80px;
}

.iask-card-ul{
  display: flex;
  justify-content: space-between;
}

.iask-card-ul li{
  width: 384px;
  height: 248px;
  position: relative;
  padding: 40px;
  background-color: #f2f6f9;
  box-sizing: border-box;
}

.iask-card-li-title{
  font-size: 24px;
  color: #4da2f3;
  line-height: 48px;
  text-align: left;
  margin-bottom: 15px;
}

.iask-card-ul li p{
  font-size: 14px;
  color: #000;
  line-height: 24px;
  text-align: left;
  margin-bottom: 8px;
}

.iask-card-img{
  position: absolute;
  right: 0;
  bottom: 0;
}

.iask-clients{
  display: flex;
  justify-content: space-between;
  padding: 50px 0 ;
}

.iask-clients a, .iask-client-img{
  display: block;
}

.iask-footer{
  background: #1d2731;
}
.iask-footer-center{
  width: 1234px;
  margin: 0 auto;
  padding: 10px 0;
}

.iask-footer-center p{
  line-height: 24px;
  font-size: 14px;
  color: #fff;
}

.iask-login-box{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
}

.iask-bg{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
}

.iask-login-form{
  position: absolute;
  width: 446px;
  left: 50%;
  margin-left: -223px;
  top: 180px;
  background-color: #2082dd;
  color: #fff;
  box-sizing: border-box;
  padding: 24px 54px 44px;
}

.iask-login-form-head{
  font-size: 30px;
  line-height: 42px;
  text-align: left;
}

.iask-login-input-wrapper{
  display: flex;
  height: 36px;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 5px;
}

.iask-login-input-wrapper input{
  height: 26px;
  line-height: 26px;
  border: 0;
  width: 100%;
  outline: none;
  background-color: #2082dd !important;
  box-shadow: 0 0 0px 1000px #2082dd inset;
  -webkit-box-shadow: 0 0 0px 1000px #2082dd inset;
  color: #fff !important;
}
.iask-login-input-wrapper input:-webkit-autofill{
  color: #fff !important;
}

.iask-login-input-wrapper input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #d9d9da;
}
.iask-login-input-wrapper input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d9d9da;
}
.iask-login-input-wrapper input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d9d9da;
}
.iask-login-input-wrapper input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d9d9da;
}

.iask-get-code{
  height: 24px;
  line-height: 24px;
  border-radius: 14px;
  border: 1px solid #ccc;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}
.iask-get-code.disabled{
  cursor: not-allowed;
  color: #d9d9da;
}

.iask-login-check-word{
  height: 24px;
  line-height: 24px;
  color: #0ff;
  text-align: left;
  padding-left: 8px;
  font-size: 12px;
}

.iask-login-btn{
  width: 100%;
  height: 40px;
  line-height: 38px;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 15px;
  border: 1px solid #fff;
  cursor: pointer;
}

.iask-login-bottom{
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  font-size: 12px;
}
.iask-login-bottom-right{
  display: flex;
}

.iask-login-to-login{
  margin-right: 10px;
}

.iask-login-bottom-btn{
  color: #fff;
  cursor: pointer;
}

.iask-userinfo{
  display: flex;
}

.iask-logout{
  cursor: pointer;
}

.iask-nickname{
  margin-left: 12px;
}