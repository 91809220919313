.w1_header{
    height: 56px;
    padding: 16px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 2px 0px 8px rgba(0,0,0,0.1);
    position: relative;
    z-index: 11;
}
.w1_container{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.w1_content{
    height: 100%;
    flex-shrink: 1;
    display: flex;
    overflow: hidden;
}
.w1_header_r{
    display: flex;
    align-items: center;
}
.w1_tips{
    margin-right: 16px;
}
/* left */
.w1_left{
    width: 188px;
    display: flex;
    flex-shrink: 0;
    box-shadow: 2px 0px 8px rgba(0,0,0,0.1);
    background-color: #fff;
}
.w1_left_title{
    color: #155BD4;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 76px;
    height: 100%;
    padding-top: 32px;
    box-sizing: border-box;
    border-right: 1px solid #979797;
    flex-shrink: 0;
    position: relative;
    z-index: 10;
    background-color: #fff;
}
.w1_left_title span{
    font-size: 12px;
    margin-top: 8px;
}

/* center */
.w1_center{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    min-width: 686px;
}
.w1_center_right{
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f4f6;
    box-sizing: border-box;
    position: relative;
}
.w1_center_right.small{
    display: block;
}
.w1_center_right.small .w1_center_phone{
    float: right;
    right: 0;
    margin-right: 113px;
}
/* right */
.w1_right{
    width: 376px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 0px 8px rgba(0,0,0,0.1);
    position: relative;
    z-index: 10;
}

.success_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.success_box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 144px;
}
.success_box img{
    display: block;
    margin-bottom: 24px;
}

.success_title{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
}
.success_tips{
    font-size: 14px;
    color: #646566;
    line-height: 20px;
    margin-bottom: 24px;
}
.go_on_btns{
    display: flex;
    line-height: 20px;
    margin-top: 40px;
    width: 100%;
    justify-content: space-between;
}
.go_new{
    color: #155BD4;
    cursor: pointer;
}
.go_on_btns a{
    color: #155BD4;
}

.zent-tabs-panel-wrapper{
    width: 100%;
}