.App {
  text-align: center;
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  text-decoration: underline;
}

html, body, .root{
  height: 100%;
  font-size: 14px;
}

/* 富文本样式 */
.preview_module_wrapper b, .preview_module_wrapper strong{
  font-weight: 600;
}
.preview_module_wrapper i,.preview_module_wrapper em {
  font-style: italic;
}
.preview_module_wrapper blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}
.preview_module_wrapper .list-paddingleft-2 {
  padding-left: 30px;
}
.preview_module_wrapper table tr.firstRow th {
  border-top-width: 2px;
}
.preview_module_wrapper .selectTdClass{background-color:#edf5fa !important}
.preview_module_wrapper table.noBorderTable td,
.preview_module_wrapper table.noBorderTable th,
.preview_module_wrapper table.noBorderTable caption{border:1px dashed #ddd !important}
.preview_module_wrapper table{margin-bottom:10px;border-collapse:collapse;display:table;}
.preview_module_wrapper td,.preview_module_wrapper th{padding: 5px 10px;border: 1px solid #DDD;}
.preview_module_wrapper caption{border:1px dashed #DDD;border-bottom:0;padding:3px;text-align:center;}
.preview_module_wrapper th{border-top:1px solid #BBB;background-color:#F7F7F7;}
.preview_module_wrapper table tr.firstRow th{border-top-width:2px;}
.ue-table-interlace-color-single{ background-color: #fcfcfc; } 
.ue-table-interlace-color-double{ background-color: #f7faff; }
.preview_module_wrapper td p{margin:0;padding:0;}

.preview_module_wrapper ol,.preview_module_wrapper ul{margin:0;padding:0;width:95%}li{clear:both;}
.preview_module_wrapper ol.custom_cn{list-style:none;}.preview_module_wrapper ol.custom_cn li{background-position:0 3px;background-repeat:no-repeat}
li.list-cn-paddingleft-1{padding-left:25px}
li.list-cn-paddingleft-2{padding-left:40px}
li.list-cn-paddingleft-3{padding-left:55px}

.preview_module_wrapper .pagebreak{display:block;clear:both !important;cursor:default !important;width: 100% !important;margin:0;}

.preview_module_wrapper pre{background:#f8f8f8;white-space: initial;}
.preview_module_wrapper td[valign="top"]{vertical-align: top;}
.preview_module_wrapper td[valign="bottom"]{vertical-align: bottom;}
.preview_module_wrapper td[valign="middle"]{vertical-align: middle;}

