.w1_left{
    width: 188px;
    display: flex;
    flex-shrink: 0;
    box-shadow: 2px 0px 8px rgba(0,0,0,0.1);
    background-color: #fff;
    -webkit-user-drag: none;
}
.w1_left_title{
    color: #155BD4;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 76px;
    height: 100%;
    padding-top: 32px;
    box-sizing: border-box;
    border-right: 1px solid #979797;
    flex-shrink: 0;
}
.w1_left_title span{
    font-size: 12px;
    margin-top: 8px;
}
.w1_panel{
    width: 188px;
    flex-shrink: 0;
    height: 100%;
    overflow: hidden;
}
.w1_menu_wrapper{
    width: 220px;
    height: 100%;
    overflow: auto;
    padding-top: 1px;
}
.w1_panel_item{
    width: 188px;
    margin-top: 30px;
}
.w1_panel_item_title {
    display: flex;
    line-height: 24px;
    margin-bottom: 24px;
    padding-left: 10px;
    align-items: center;
    cursor: pointer;
    user-select: none;
}
.w1_panel_item_title span{
    margin-left: 5px;
}
.w1_panel_item ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;
    user-select: none;
    /* transition: 0.5s ease; */
    /* height: 192px; */
}

.w1_panel_item ul li,.move_cell{
    position: relative;
    width: 80px;
    /* height: 74px; */
}
.move_cell{
    position: fixed;
    background-color: #155BD4;
    color: #fff;
    z-index: 9;
    cursor: move;
}
.w1_panel_item ul li:hover{
    background-color: #ebedf0;
}
.w1_panel_item ul li img,.move_cell img{
    -webkit-user-drag: none;
}
.w1_panel_item ul li span,.move_cell span{
    position: absolute;
    display: block;
    left: 0;
    top: 44px;
    width: 100%;
    line-height: 24px;
    user-select: none;
    font-size: 12px;
}