
.wj_title{
    width: 100%;
    background-color: #f7f8fa;
    padding:20px;
    font-size: 16px;
    line-height: 26px;
    color: #323233;
    text-align: left;
    margin-bottom: 24px;
    box-sizing: border-box;
}
.wj_go_back{
    float: right;
    padding: 5px;
}
.title_left {
    margin-right: 10px;
}
.sub_title {
    color: #969799;
    font-size: 12px;
}
.top_btn_view {
    width: 100%;
    text-align: left;
}
.top_btn {
    margin-left: 10px;
}
.data_row {
    text-align: left;
    margin-top: 40px;
}
.row_title {
    background-color: #F7F8FA;
    width: 99%;
    height: 20px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding:10px;
}
.col_1 {
    width: 20%;
}
.col_2 {
    width: 20%;
}
.col_3 {
    width: 60%;
}
.row_content {
    padding:10px;
    border-bottom: 1px solid #F7F8FA;
    display: flex;
    flex-direction: row;
}
.col_img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.bx {
    color: red;
    margin-right: 5px;
}
.otherLink {
    margin-left: 10px;
}
.no_border {
    border-bottom:0px;
}
.no_permissions {
    background-color: #F7F8FA;
    border: 1px solid #DCDEE0;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    text-align: center;
    padding-top: 30px;
    color: #757577;
}
.page_bottom {
    width: 100%;
    height: 30px;
}

.pop_head {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #DCDEE0;
}
.pop_head_left {
    text-align: left;
    width: 50%;
    display: flex;
    align-items: center;
    padding-top:20px;
    padding-left: 10px;
    padding-bottom: 20px;
}
.pop_head_right {
    width: 50%;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 20px;
    padding-right: 10px;
    padding-bottom: 20px;
}
.pop_contnet {
    width: 100%;
    /* padding:10px; */
    /* margin:10px; */
}
.other_top {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.other_top_left {
    text-align: left;
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.other_top_right {
    width: 50%;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 10px;
}
.search_input {
    width: 180px;
}
.p_content {
    padding:10px;
}
.image_op {
    display: flex;
    flex-direction: row;
}