.a_content {
    width: 100%;
}
.a_row {
    width: 100%;
    text-align: left;
    border-bottom: #f7f8fa 1px solid;
    margin-top:20px;
}
.a_row_view {
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
.a_row_view div {
    display: flex;
    align-items: center;
}
.a_img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
.a_checkbox_title {
    margin-right: 10px;
}
.otherLink{
    cursor: pointer;
}